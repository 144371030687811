import { AnyObject } from 'tsdef'

export interface EventData<Data extends AnyObject = AnyObject> {
  data: Data
}

export class Event<EventName extends string = string> {
  name

  constructor(eventName: EventName) {
    this.name = eventName
  }
}
