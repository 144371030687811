import { debounce } from 'lodash'
import pWaitFor from 'p-wait-for'
import { useAsyncFn, useUpdateEffect } from 'react-use'
import { useSnapshot } from 'valtio'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'

/** ### 同步訂閱清單變化，呼叫後端推值 */
export const useSignalrSyncSubscriptions2 = () => {
  const subscriptions = useSnapshot(signalrStore2.subscriptions)

  const [invokeState, invoke] = useAsyncFn(async () => {
    await pWaitFor(() => signalrStore2.isConnected, { interval: 150, timeout: 5000 })
    await signalrStore2.invokeSubscriptions()
  }, [])

  /**
   * 如果有多個組件，有多組有可能重複的 symbol，它們一起很快速的 addQuote, removeQuote；例如發生在「signal-wall」那邊可能需要 polling 的狀況，它
   * request 的間隔很短；那麼會可能發生 async invoke(unsub) 比 async invoke(sub) 還要快，就造成部份 symbol
   * 沒有訂閱到報價。workaround：延遲呼叫訂閱。
   */
  const $invoke = debounce(invoke, 1000)

  useUpdateEffect(() => {
    $invoke()
  }, [
    subscriptions.BidAskSymbols,
    subscriptions.QuoteSymbols,
    subscriptions.QuoteTopics,
    subscriptions.TickSymbols,
    subscriptions.TradeInfoTopics,
  ])
}
