import Emittery from 'emittery'
import { proxy, ref } from 'valtio'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { EventOfDocumentVisibilityChange } from '~/pages/heineken_template/_fr/fr_events/_EventOfDocumentVisibilityChange'

export const fr_events = proxy(
  new (class FrEvents {
    /** 事件引擎 */
    emitter = ref(new Emittery())

    /** 可用的全局事件 */
    on = {
      /**
       * - 瀏覽器 分頁(Tab) 被切換到其它分頁時觸發（若兩頁分為兩視窗不觸發）
       * - 瀏覽器被隱藏、或最小化時觸發
       * - 手機、電腦休眠、鎖頻、以及被喚醒後又到達該分頁時觸發
       * - 底層使用 `document.addEventListener('visibilitychange', function () {})`
       * - 參考文件：https://developer.mozilla.org/zh-CN/docs/Web/API/Document/visibilityState
       *
       * ### 用途
       *
       * - Charting 的 `nosleep`
       * - 手機休眠後回來網頁，使 `signalr` 連線可以活過來
       * - Firebase 的 refresh_token 機制 (不確定?)
       *
       * @example
       *   //
       *   // 底層：在 template 安裝
       *   templateProps.hooks.add(fr_events.on.documentVisibilityChange.useInstall)
       *
       *   //
       *   // UI層：在各種組件裡面
       *   function Component() {
       *     const currentPage = useSnapshot(fr_events.on.documentVisibilityChange.data)
       *
       *     return (
       *       <div>
       *         {currentPage.isVisibility ? '客戶正在本頁密切看盤中' : '客戶的網頁隱藏或休眠中'}
       *       </div>
       *     )
       *   }
       */
      documentVisibilityChange: new EventOfDocumentVisibilityChange(),
    }
  })(),
)
