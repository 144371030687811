import React, { memo, useState } from 'react'
import { Button, Dialog, DialogActions } from '@mui/material'
import { __IS_CLIENT__ } from '~/utils/__IS_CLIENT__'
import { css } from '@emotion/react'
import useMedia from '~/hooks/useMedia'
import { fontWeight600 } from '~/css/font'

const UserAgentHintDialog = memo<
  ReactProps<{
    userAgentList?: { key: string; chineseKey: string; message: string }[]
  }>
>(function UserAgentHintDialog(props) {
  const browserAgent = __IS_CLIENT__ ? navigator.userAgent : ''
  const { isPc } = useMedia()
  const shouldShowHint =
    props.userAgentList?.some(ua => browserAgent.indexOf(ua.key) != -1) ?? false
  const [open, setOpen] = useState<boolean>(shouldShowHint)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <div
        css={css`
          width: 320px;
          padding: ${isPc ? 32 : 16}px;
          font-size: 1.2rem;
          span {
            ${fontWeight600};
          }
        `}
      >
        {props.userAgentList?.map(ua => {
          const matchSituation = browserAgent.indexOf(ua.key) != -1
          return matchSituation ? (
            <p key={ua.key}>
              偵測到您使用<span>{ua.chineseKey}</span>，{ua.message}
            </p>
          ) : null
        })}
      </div>
      <DialogActions>
        <Button onClick={handleClose}>關閉</Button>
      </DialogActions>
    </Dialog>
  )
})

export default UserAgentHintDialog
