import { memo } from 'react'
import UserAgentHintDialog from '~/modules/futuresai-static/UserAgentHintDialog'

const UserAgentWarning = memo(function UserAgentWarning() {
  return (
    <UserAgentHintDialog
      userAgentList={[
        {
          key: 'Line',
          chineseKey: 'Line內部瀏覽器',
          message: '為提供最佳網站服務品質，請改用 Chrome 或是 Safari 瀏覽器',
        },
        {
          /* IE 排版引擎 */
          key: 'Trident',
          chineseKey: 'IE瀏覽器',
          message: '建議使用其他瀏覽器 Chrome, Edge 或是 Safari 瀏覽器等開啟網頁',
        },
      ]}
    />
  )
})
export default UserAgentWarning
